// Toasty

.toasty-container {
  box-sizing: border-box;
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
  position: fixed;
  z-index: 100;

  &.top-right { top: 0; right: 0; }
  &.top-left { top: 0; left: 0; }
  &.top-center { top: 0;left: 50%; transform: translateX(-50%); }

  &.bottom-right { bottom: 0; right: 0; }
  &.bottom-left { bottom: 0; left: 0; }
  &.bottom-center { bottom: 0;left: 50%; transform: translateX(-50%); }

  &> div {
    font-size: 14px;
    color: #fff;
    padding: 10px;
    border: 2px solid #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  }

  .bg-danger {
    color: #FF8F60;
    background: #FFF4EF !important;
    /*border: 1px solid #ffd3b5;*/
  }

  .bg-success {
    color: #fff;
    background: #222222 !important;
    /*border: 1px solid #ffd3b5;*/
  }

}

@media (max-width: 500px) {
  .toasty-container {
    width: 100%;
  }
}