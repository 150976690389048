$primary: #32735F;
$secondary: #C9E0E0;
$danger: #FF8F60;
$warning: red;

$light: #595959;
$dark: #222222;
// custom variables used in style.scss
$lighter: #9E9E9E;

// variant
$primary-light: #EFF2F2;
$danger-light: #FFF4EF;

// texts
$text-default-color: #222222;

// inputs
$input-color: $text-default-color;
$input-bg: #fff;
$input-border: #9E9E9E;
$input-border-radius: 8px;
$input-error-color: #ffa47e;
$input-height: 50px;

// links
$link-color: $primary;
$link-hover-color: $primary;

// snackbars
$snackbar-cta: #7BB8A5