/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');*/

@import url('https://fonts.cdnfonts.com/css/gotham');

@font-face {
  font-family: 'opensans';
  src: url('../fonts/opensans/OpenSans-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'opensans-bold';
  src: url('../fonts/opensans/OpenSans-Bold.ttf');
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: 'opensans-semibold';
  src: url('../fonts/opensans/OpenSans-SemiBold.ttf');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'opensans-italic';
  src: url('../fonts/opensans/OpenSans-Italic.ttf');
  font-weight: normal;
  font-style: italic;
}


@font-face {
  font-family: 'newyork';
  src: url('../fonts/newyork/NewYorkExtraLarge-Regular.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'newyork-bold';
  src: url('../fonts/newyork/NewYorkExtraLarge-Bold.otf');
  font-weight: 700;
  font-style: bold;
}
@font-face {
  font-family: 'newyork-semibold';
  src: url('../fonts/newyork/NewYorkExtraLarge-Semibold.otf');
  font-weight: 600;
  font-style: normal;
}


