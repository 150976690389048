/* Stripe */
.StripeElement {
  margin-top: 6px;
}




/* Email Input widget */

/* Min width for shadow input so it can be found on mobile for opening pasting option */
.css-b8ldur-Input, .css-1g6gooi {
  z-index: 1 !important;
  min-width: 250px;
}
.css-b8ldur-Input > div, .css-1g6gooi > div {
  min-width: 250px;
}
.css-b8ldur-Input input, .css-1g6gooi input {
  /*border: 2px solid red !important;
  background: red !important;*/
  min-width: 250px !important;
}




.css-yk16xz-control {
  color: $input-color !important;
  border-color: $input-border !important;
  border-radius: $input-border-radius !important;
  height: auto;
  min-height: $input-height !important;

  &.is-invalid {
    border-color: $danger !important;
    background: none !important;
  }
}

.css-1wa3eu0-placeholder {
  color: #9E9E9E !important;
}

/* this is input when focused */
.css-1pahdxg-control {
  border-radius: $input-border-radius !important;
  border-color: $input-border !important;
  box-shadow: none !important;
  height: auto;
  min-height: $input-height !important;

  &:hover {
    border-color: $input-border !important;
  }
  &:focus {
    border-color: $input-border !important;
  }
}

.css-1rhbuit-multiValue {
  background-color: #EFF2F2 !important;
  color: #32735F;
}

/* this is the option menu */
.css-26l3qy-menu {

}





$btn-main-color: $primary;
$black-color: #343434;
$red-color: $danger;
$green-color: $primary;
$orange-color: $danger;
$ios-green: $primary;

.switch {

  margin: 0;
  padding: 0;

  input[type="checkbox"] {
    display: none;
    visibility: hidden;
  }

  /*
  Switcher Style
   */
  .switcher {
    display: inline-block;
    border-radius: 100px;
    min-width: 35px;
    width: 35px;
    height: 15px;
    background-color: #ccc;
    position: relative;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    cursor: pointer;


    &:before {
      content: "";
      display: block;
      width: 20px;
      height: 20px;
      background-color: #fff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6);
      border-radius: 50%;
      margin-top: -2.8px;
      position: absolute;
      top: 0;
      left: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      margin-right: 0;
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -ms-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;
    }

    &:active:before {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6),
      0 0 0 10px rgba($btn-main-color, 0.3);
      transition: all, 0.1s;
    }
  }

  .label {
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    vertical-align: middle;
    margin: 0 5px;
  }

  /*
  When Checked
   */
  input[type="checkbox"]:checked + .switcher {
    background-color: lighten($btn-main-color, 0);

    &:before {
      right: 100%;
      margin-left: 16px;
      /*background-color: $btn-main-color;*/
    }
  }

  & [disabled]:not([disabled="false"]) + .switcher {
    background: #ccc !important;

    &:active:before {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    }

    &:before {
      background-color: #e2e2e2 !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    }
  }

  /*
  Switch small style
   */
  &.switch-small {
    .switcher {
      width: 20px;
      height: 9px;

      &:before {
        width: 12px;
        height: 12px;
        margin-top: -2px;
      }
    }

    .label {
      font-size: 18px;
    }

    input[type="checkbox"]:checked + .switcher {
      &:before {
        margin-left: 8px;
      }
    }
  }

  /*
  Switch large style
   */
  &.switch-large {
    .switcher {
      width: 52px;
      height: 22px;

      &:before {
        width: 30px;
        height: 30px;
        margin-top: -4px;
      }
    }

    .label {
      font-size: 18px;
    }

    input[type="checkbox"]:checked + .switcher {
      &:before {
        margin-left: 22px;
      }
    }
  }




  /*
  Switch Black color style
   */

  &.switch-black {
    input[type="checkbox"]:checked + .switcher {
      background-color: lighten($black-color, 20);

      &:before {
        background-color: $black-color;
      }
    }

    .switcher:active:before {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6),
      0 0 0 10px rgba($black-color, 0.3);
    }
  }

  /*
  Switch Red color style
   */

  &.switch-red {
    input[type="checkbox"]:checked + .switcher {
      background-color: lighten($red-color, 20);

      &:before {
        background-color: $red-color;
      }
    }

    .switcher:active:before {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6),
      0 0 0 10px rgba($red-color, 0.3);
    }
  }

  /*
  Switch green color style
   */

  &.switch-green {
    input[type="checkbox"]:checked + .switcher {
      background-color: lighten($green-color, 20);

      &:before {
        background-color: $green-color;
      }
    }

    .switcher:active:before {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6),
      0 0 0 10px rgba($green-color, 0.3);
    }
  }

  /*
  Switch green color style
   */

  &.switch-orange {
    input[type="checkbox"]:checked + .switcher {
      background-color: lighten($orange-color, 20);

      &:before {
        background-color: $orange-color;
      }
    }

    .switcher:active:before {
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.6),
      0 0 0 10px rgba($orange-color, 0.3);
    }
  }
}

/* ---------------------------------------------
------------------[IOS style]----------------------------
 --------------------------------------------- */

.switch.ios {
  /*
Switcher Style
 */
  .switcher {
    width: 45px;
    height: 24px;
    background-color: #fff;
    border: 1px solid #d3d3d5;
    box-shadow: 0 0 0 0 $ios-green inset;
    -webkit-transition: all 0.4s ease;
    -moz-transition: all 0.4s ease;
    -ms-transition: all 0.4s ease;
    -o-transition: all 0.4s ease;
    transition: all 0.4s ease;

    &:before {
      width: 24px;
      height: 24px;
      background-color: #fff;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
      margin-top: 0;
      top: -1px;
      right: -1px;
      margin-right: 0;
      border: 1px solid #d3d3d5;
      -webkit-transition: all 0.4s ease;
      -moz-transition: all 0.4s ease;
      -ms-transition: all 0.4s ease;
      -o-transition: all 0.4s ease;
      transition: all 0.4s ease;
    }

  }



  /*
  When Checked
   */
  input[type="checkbox"]:checked + .switcher {
    background-color: #fff;
    box-shadow: 0 0 0 20px $ios-green inset;

    &:before {
      right: 100%;
      margin-right: -22px;
      background-color: #fff;
    }
  }

  & [disabled]:not([disabled="false"]) + .switcher {
    background: #ccc !important;

    &:active:before {
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    }

    &:before {
      background-color: #e2e2e2 !important;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2) !important;
    }
  }



  /*
  Switch large style
   */
  &.switch-large {
    .switcher {
      width: 62px;
      height: 34px;

      &:before {
        width: 34px;
        height: 34px;
      }
    }

    .label {
      font-size: 18px;
    }

    input[type="checkbox"]:checked + .switcher {
      &:before {
        margin-right: -30px;
      }
    }
  }


  /*
  Switch xlarge style
   */
  &.switch-xlarge {
    .switcher {
      width: 80px;
      height: 40px;

      &:before {
        width: 40px;
        height: 40px;
        margin-top: 0;
      }
    }

    .label {
      font-size: 24px;
    }

    input[type="checkbox"]:checked + .switcher {
      &:before {
        margin-right: -39px;
      }
    }
  }


  /*
  Switch Black color style
   */

  &.switch-black {
    .switcher {
      box-shadow: 0 0 0 0 $black-color inset;
    }

    input[type="checkbox"]:checked + .switcher {
      box-shadow: 0 0 0 20px $black-color inset;
      background: #ffffff;

      &:before {
        background-color: #fff;
      }
    }

    .switcher:active:before {
      box-shadow: none;
    }
  }

  /*
  Switch Red color style
   */


  &.switch-red {
    .switcher {
      box-shadow: 0 0 0 0 $red-color inset;
    }

    input[type="checkbox"]:checked + .switcher {
      box-shadow: 0 0 0 20px $red-color inset;
      background: #ffffff;

      &:before {
        background-color: #fff;
      }
    }

    .switcher:active:before {
      box-shadow: none;
    }
  }

  /*
  Switch green color style
   */

  &.switch-green {
    .switcher {
      box-shadow: 0 0 0 0 $green-color inset;
    }

    input[type="checkbox"]:checked + .switcher {
      box-shadow: 0 0 0 20px $green-color inset;
      background: #ffffff;

      &:before {
        background-color: #fff;
      }
    }

    .switcher:active:before {
      box-shadow: none;
    }
  }

  /*
  Switch green color style
   */

  &.switch-orange {
    .switcher {
      box-shadow: 0 0 0 0 $orange-color inset;
    }

    input[type="checkbox"]:checked + .switcher {
      box-shadow: 0 0 0 20px $orange-color inset;
      background: #ffffff;

      &:before {
        background-color: #fff;
      }
    }

    .switcher:active:before {
      box-shadow: none;
    }
  }
}
