
@import "node_modules/bootstrap/scss/_functions";
@import "node_modules/bootstrap/scss/_variables";
@import "./vars";



$theme-colors: (
  primary: $primary,
  secondary: $secondary,
  success: $green,
  info: $cyan,
  warning: $warning,
  danger: $danger,
  light: $light,
  dark: $dark
);

@import "node_modules/bootstrap/scss/bootstrap";
@import "./style";


